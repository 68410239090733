import Swal from 'sweetalert2'

// modal para mostrar mensaje simple 
// simple modal message 
export const sweetalert = (title, text, icon) => {
    Swal.fire({
        title,
        text,
        icon,
        allowOutsideClick: false,
        showCloseButton: true,
        confirmButtonColor: '#00c389',
        })  
}

// modal para validar el logout 
// validate logout modal 
export const sweetalertLogout = (title, text, icon, logout) => {
    Swal.fire({
        title,
        text,
        icon,
        allowOutsideClick: false,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, salir',
        confirmButtonColor: '#00c389',
        cancelButtonText: 'No.',
        }).then((result) => {
            if (result.isConfirmed){
                logout()
            }
        })
}

export const sweetAlertExpulse = (title, text, icon, logout) => {
    Swal.fire({
        title,
        text,
        icon,
        allowOutsideClick: false,
        showCloseButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#00c389',
        }).then((result) => {
            if (result.isConfirmed){
                logout()
            }
        })
}
