import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../context/app/AppContext";
import {RouterPrivate, RouterPublic } from "./Router";

const Home = () => {
  const { datalogin } = useContext(AppContext);

  const Routers = () => {
    return (
      <>
      {
        datalogin ? <RouterPrivate /> : <RouterPublic />
      }
      </>
    )
  }
  return (
    <>
      <Routers />
    </>
  );
};

export default Home;
