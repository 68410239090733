import React from 'react'
import AppState from '../context/app/AppState'
import {UploadCsvComponent} from '../components/uploadcsv/UploadCsv'
import { Toaster } from 'react-hot-toast'

const UploadCsv = () => {
    return (
        <AppState>
            <Toaster 
              position="top-center"
              reverseOrder={false}
            />
            <UploadCsvComponent />
        </AppState>

    )
}

export default UploadCsv