import React, { useRef, useState, useContext, useEffect } from 'react' 
// assets 
import Logo from '../../assets/logo.svg'
import SearchCsvLogo from '../../assets/searchCsvLogo.svg'
import UploadScvLogo from '../../assets/uploadScvLogo.svg'
import logoutLogo from '../../assets/logout.svg'
import Copy from '../../assets/copy.svg'
import Loader from '../../helpers/Loader'
// libs 
import SweetAlert2 from 'react-sweetalert2';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast from 'react-hot-toast'
// context
import AppContext from '../../context/app/AppContext'
import Card from '@mui/material/Button';
import '../../Styles/styles.css';
//components
import DateRange from './DateRange';

export const UploadCsvComponent = () => {

    const { 
        loading, // loading state
        UploadCsv, // function to upload csv
        requestLogout, // function to log out user
        swalProps, // props for modal with components
        csvErrors, // state that has all error to show on modal
        file,  // all csv file data
        responseCsv, // response
        setFile, // funcion to set file state
        fileName, // state that have only the file name
        setFileName, // function to change the file name
        cleanData, // function to clean states data
        fileToCheck // state for request file checking after conection problems
     } = useContext(AppContext)
    const services = ['Rocket','Delivery','Rides', 'Fun', 'Prime']
    const types = ['Cliente','Comercio','Driver']
    const errorsIDs = [
		'YUC',
		'YU',
		'RCK',
		'YRC',
		'YR',
		'PM',
		'FN',
	]

    const inputFile = useRef(null) 
    // input radio value to change mode
    const [type, setType] = useState('');
    const [value, setValue] = useState('');
    const [dateRange, setDateRange] = useState([]);

    // const [overlap, setOverlap] = useState(false);

    const downloadFile = () => {
        const blob = new Blob(responseCsv.dataCsv, { type: 'text/csv; charset=utf-8' });

        const url = window.URL.createObjectURL(blob);
    
        window.open(url);
    }


    const onOpenFileSelector = () => {
        // `current` points to the mounted file input element
       inputFile.current.click();
      };

    const uploadFile = async () => {
        // console.log(file)
        let dates = dateRange
        const datepickersDiv = document.getElementById("datepickers")
        if(!datepickersDiv) {dates = []}

        const response = await UploadCsv(file, value.toLowerCase(), type.toLocaleLowerCase(), dates, setFile, setFileName)
        if(response){
            cleanData()
            setType('')
            setValue('')
            setDateRange([])
        }
        setType('')
        setValue('')
        setDateRange([])
    }

    const setFileData = (data) => {
        setFileName(data.value)
        setFile(data.files[0])
    }

    // const setDropData = (data) => {
    //     console.log('setFileData', data)
    //     setFileName(data.name)
    //     setFile(data)
    // }2


    const handleChangeType = (event) => {
        let data =''
         if(event.target.value === 'Cliente'){
            data = 'client' 
         } else if (event.target.value === 'Comercio'){
            data = 'corporate'
         } else {
            data = 'driver'
         }
        setType(data);
    };

    const handleChangeService = (event) => {
        setValue(event.target.value);
    };

    /**
     * launch the toast notification when the user copy to clipboard
     * @param {boolean} result 
     */
     const launchToast = () => {
        toast.success('Copiado!')
    }

    return (
        // <FileDrop
        // className="file-drop"
        // //   onFrameDragEnter={(event) => setOverlap(true)}
        // onFrameDragLeave={(event) => setOverlap(false)}
        // //   onFrameDrop={(event) => setOverlap(false)}
        // onDragOver={(event) => setOverlap(true)}
        // onDragLeave={(event) => setOverlap(false)}
        // onDrop={(files, event) =>{
        //     if(files){
        //         // console.log('onDrop!', files[0], event)
        //         setOverlap(false)
        //         setDropData(files[0])
        //     }
        //     }}
        // >
        // </FileDrop>
        <div className="page-container" style={{backgroundColor: "#fff"}}>
            <SweetAlert2 
                {...swalProps} 
                didClose={() => {
			        window.localStorage.removeItem('file_name')
                    window.location.reload()
                }}
            >
                {
                    (csvErrors && csvErrors.length > 0) &&
                    <div className='sweet-alert-errors-container'>
                        {
                            csvErrors.map(err => {
                            console.log("🚀 ~ file: UploadCsv.jsx ~ line 127 ~ UploadCsvComponent ~ err", err)
                                let idFormatted =  err.orderNumber
                                errorsIDs.forEach(id => {
                                    idFormatted = idFormatted.replace(id, '')
                                })
                                if(!err.orderNumber || !err.errorMessage || err.errorMessage === null){
                                    return null
                                }
                                return (
                                    <>
                                        <div key={err.orderNumber}>
                                                <div className='id-container'>
                                                    <p className='text-left bold mb-0'>ID: {idFormatted} </p>
                                                    <CopyToClipboard text={idFormatted} onCopy={launchToast}>
                                                        <img src={Copy} style={{ cursor: 'pointer', margin: '0 15px 5px' }} alt="copy" />
                                                    </CopyToClipboard>
                                                </div>
                                            {
                                                err.errorMessage &&
                                                err.errorMessage.map(el => {
                                                    return (
                                                        <p key={el} className='text-left m-0'>{`- ${el}`}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                }
            </SweetAlert2>
            <div className="logout" onClick={()=> requestLogout()}><img src={logoutLogo} alt="logo" className="logout-icon" /></div>
            <img className="yummy-logo" src={Logo} alt="logo" style={{ marginBottom: 20 }} />
            {/* {
                overlap &&
                <div className='overlap'>
                    <p>Suelta para agregar el archivo</p>
                </div>
            } */}
            {
                fileName &&
                <>
                    <p className="title">¿A quien deseas facturar?</p>
                    <div className="type-container">
                    {   
                        types.map(type => (
                            // <div className="selected-container" onClick={selectType}><p>{type}</p></div>
                            <label key={type} className="selected-container">
                            <input type="radio" className="input-radio" name="type" value={type} onChange={(e => handleChangeType(e))} />
                            <span className="checkmark">{type}</span>
                            </label>  
                        ))
                    }
                    </div>
                    <p className="title">Selecciona un servicio</p>
                    <div className="type-container">
                    {   
                        services.map(service => (
                            // <div className="selected-container" onClick={selectType}><p>{type}</p></div>
                            <label key={service} className="selected-container">
                            <input type="radio" className="input-radio" name="service" value={service} onChange={(e => handleChangeService(e))} />
                            <span className="checkmark">{service}</span>
                            </label>  
                        ))
                    }
                    </div>
                    {type === 'corporate' &&
                     value === 'Rides' && (
                        <div id="datepickers">
                            <p className="title">Selecciona un rango de fechas</p>
                            <div className="type-container">
                            {
                                <DateRange setDateRange={setDateRange} />
                            }
                            </div>
                        </div>
                    )}
                </>
            }
            {(file && !loading) && <p className="text-title">Archivo: {fileName}</p>}
            <input type='file' id='file' ref={inputFile} accept=".csv" onChange={(e) => setFileData(e.target)} style={{display: 'none'}}/>
            <div className="actions">
                {
                    fileName ? 
                    <>
                    {
                        loading ? 
                        <>
                            <Loader /> 
                            <p className="text-title">Subiendo archivo: <span className='bold'>{fileName}</span></p>
                            <p className="text-title">Por favor espera un momento...</p>
                        </>
                        :
                        <button className={value && type ? "btn-yummy btn-bg" : " btn-yummy btn-bg-disabled"} disabled={value && type ? false : true} onClick={uploadFile}>
                            <img className="btn-img" src={UploadScvLogo} alt="logo" />
                            <p>Subir Csv</p>
                        </button>

                    }
                    </>
                    :
                    <>
                    {
                        !fileToCheck && 
                        <div>
                            <button className="btn-yummy" onClick={onOpenFileSelector}>
                                <img className="btn-img" src={SearchCsvLogo} alt="logo" />
                                <p>Seleccionar Csv</p>
                            </button>
                            {responseCsv.billCount &&
                                <>
                                    <Card className='mt-3 w-15 flex flex-column align-items-start border-surface color-secondary' variant="outlined">
                                        <span><b>Archivo:</b> {responseCsv.fileName}</span>
                                        <span><b>N° Facturas:</b> {responseCsv.billCount}</span>
                                        <span><b>Total:</b> {responseCsv.totalBilled}</span>
                                    </Card>
                                    <button className="mt-3 btn-yummy" onClick={downloadFile}>
                                        <img className="btn-img" src={SearchCsvLogo} alt="logo" />
                                        <p>Descargar Detalle</p>
                                    </button>
                                </>
                            }
                        </div>
                    }
                    </>
                }
                {
                    (fileName && !loading) &&
                    <button className="btn-yummy-secondary" onClick={onOpenFileSelector} >Selecionar otro archivo</button>
                }
                {
                    fileToCheck &&
                    <>
                        <Loader /> 
                        <p className="text-title">Subiendo archivo: {fileToCheck}</p>
                        <p className="text-title">Por favor espera un momento...</p>
                    </>
                }
            </div>
        </div>
    )
}