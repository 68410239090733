import React, { useState, useEffect } from "react";
import S3 from 'react-aws-s3';
// import S3 from 'aws-s3';
import AppContext from "./AppContext";
import { useHistory } from "react-router-dom";
import { sweetalert, sweetalertLogout, sweetAlertExpulse, sweetalertError } from '../../helpers/SweetAlert'
// config
import { config } from '../../helpers/s3Config'
import axios from "axios";


const AppState = ({ children }) => {

	const [file, setFile] = useState(undefined)
    const [fileName, setFileName] = useState(undefined)
	const [swalProps, setSwalProps] = useState({});
	const [csvErrors, setCsvErrors] = useState([]);
    const [fileToCheck, setFileToCheck] = useState('');
	const [responseCsv, setResponseCsv] = useState({});
	// needed for enable S3 lib to send request by frontend
	window.Buffer = window.Buffer || require("buffer").Buffer;

    // s3 config 
    // const ReactS3Client = new S3(config);

	const history = useHistory()
	// data del usuario para el login 
	// user data for login 
	const [datalogin, setDataLogin] = useState(false)
	// loading para el login 
	// login loader
	const [loading, setLoading] = useState(false)

	// roles para administrar acciones del usuario 
	// user roles 
	const [userData, sertUserData] = useState(JSON.parse(localStorage.getItem("dataLogin")))

	useEffect(()=> {
			_isAuth()
	},[])

	// evita que se pueda ingresar desde un dispositivo movil 
	// avoid to sign in on mobile 
	useEffect(()=> {
			window.screen.width < 868 && history.push("/not-responsive")
	},[])

	// funcion para tomar la data del localstorage 
	// func to get local storage data 
	const _isAuth = () => {
			let dataLocal = JSON.parse(localStorage.getItem("dataLogin"));
			if(dataLocal){
			setDataLogin(dataLocal);
			}
	}

		// almacena la data en el localStorage luego de recibir la data del usuario 
  // store the data on localstorage after getting google user data 
  const signIn = async (pswd) => {
    setLoading(true)
	try{
		const res = await fetch(`${process.env.REACT_APP_API}/auth/login`, {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				'Authorization': 'Basic '+ btoa(`${process.env.REACT_APP_ACCOUNT}:` + pswd)
			}
		})
		const jsonRes = await res.json()
		if(jsonRes){
			if(jsonRes.error) {
				sweetalert('Alto!', 'No estas autorizado para ingresar.', 'error')
				setLoading(false)
				return
			}
			localStorage.setItem("dataLogin", JSON.stringify(jsonRes));
			window.location.pathname = "/upload-csv";
			setDataLogin(jsonRes.token)
			setLoading(false)
		}
	}catch(e){
		// console.log(e)
		setLoading(false)
		sweetalert('', 'Ocurrió un error, intentalo de nuevo más tarde', 'error')
	}
  }

//   const signIn = (token, name, email) => {
//     const dataLogin = {
//       email:'email',
//       token:'qwert',
//       name: 'name'
//     };
// 	setLoading(true)
// 	setTimeout(() => {
// 		localStorage.setItem("dataLogin", JSON.stringify(dataLogin));
// 		setLoading(false)
// 		window.location.pathname = "/upload-csv";
// 		setDataLogin(dataLogin)
// 	}, 4000);
//   }

  // elimina la dat del localStorage
  // remove localStorage data 
  const logout = () => {
    window.location.pathname = "/login"
	// history.push("/login")
    localStorage.clear();
    setDataLogin(null)
  }

  // pide verificacion para cerrar sesion 
  // for request the log out by modal 
  const requestLogout = () => {
    sweetalertLogout('Quieres cerrar sesion?', '', 'warning', logout)
  }

  const cleanData = () => {
	document.getElementById('file').value=''
	setCsvErrors({})
	setSwalProps({
		show: false
	})
	setFile(null)
	setFileName(null)
  }
//   check if the file was uploaded on db 
  const checkFileIsUploaded = async (body, ) => {
	const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/billing/upload`, body, {
		headers: {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${datalogin.token}`
		},
	})

	setResponseCsv({ billCount: res.data.billCount, totalBilled: res.data.totalBilled.toFixed(2), dataCsv: res.data.data, fileName: body.fileName })

	window.localStorage.setItem('file_name', body.fileName)

	return res.data
  }

  const checkFileStatus = async (fileName) => {
	  try{
		  const body = {
			  fileName
		  }
			const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/billing/test`, body, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${datalogin.token}`
				},
			})
			return res
	  }catch(e){
   		// console.log("🚀 ~ file: AppState.jsx ~ line 150 ~ checkFileStatus ~ e", e)
		window.localStorage.removeItem('file_name')
		setFileToCheck('')
	  }
  }

//   check file status when the file is already checked on database
  const checkFile = async (fileName, setFile, setFileName) => {
	  try{
		let checking ;
		checking = await checkFileStatus(fileName)
        // console.log("🚀 ~ file: AppState.jsx ~ line 150 ~ checkFile ~ checking", checking)
		while(checking.data.pending){
			checking = await checkFileStatus(fileName)
            console.log("🚀 ~ file: AppState.jsx ~ line 153 ~ checkFile ~ checking", checking)
		}
		if(checking.data.code === '05'){
			cleanData()
			sweetalert("Listo!", "el Csv se ha subido correctamente", "success")
			window.localStorage.removeItem('file_name')
			return
		}else if(checking.data.code === '01'){
			document.getElementById('file').value=''
			const response = checking.data
            console.log("🚀 ~ file: AppState.jsx ~ line 161 ~ checkFile ~ checking", checking)
			// response.documents.map(data => sweetalert("Error!", `ID: ${data.orderNumber.replace(/[A-Z]/g, '')} - ${data.extra[0]}`))
			setCsvErrors(response.errors)
			setSwalProps({
				show: true,
				title: 'Error',
				text: 'Las siguientes ordenes contienen errores:',
				confirmButtonColor: '#00c389',
				allowOutsideClick: false,
			})
			setFile(null)
			setFileName(null)
			setFileToCheck('')
			return
		}else if(checking.data.code === '00'){
			cleanData()
			sweetalert("error!", checking.data.message, "error")
			window.localStorage.removeItem('file_name')
			return
		}else {
			cleanData()
			sweetalert("Error", "Ocurrió un error, intentalo de nuevo mas tarde", "error")
			window.localStorage.removeItem('file_name')
			return
		}
	  }catch(e){
		cleanData()
		// window.localStorage.removeItem('file_name')
		return
	  }
  }

	useEffect(()=> {
		if(datalogin){
			const checkFileOnStorage = () => {
				const filenameStorage = window.localStorage.getItem('file_name')
				if(filenameStorage){
					setFileToCheck(filenameStorage)
				}
			}
			checkFileOnStorage()
		}
	},[datalogin])

	useEffect(()=> {
		if(fileToCheck) {
			checkFile(fileToCheck, setFile, setFileName)
			
		}
	}, [fileToCheck])

  	const UploadCsv = async (
	  csv, // csv data files
	  service, // service type (rides, delivery, rocket, fun, prime)
	  type, // type of facturation (cliente, comercio)
	  dates, //fechas de facturacion
	  setFile, // func used to clear file state
	  setFileName // func used to clear file name state
	) => {
	try{
		const timestamp = + new Date()
		const nameTimestamp = timestamp+"-"+csv.name
		const body = {
			product: service.toLowerCase(),
			orientedTo: type,
			fileName: nameTimestamp,
			startDate: dates.startDate ?? "",
			endDate: dates.endDate ?? ""
		}
		setLoading(true)
		const presingUrl = await getPresingUrl(nameTimestamp)

		uploadToPresingUrl(presingUrl, csv)
		.then( async (data) => {
			const fileUploaded = await checkFileIsUploaded(body)
			if(!fileUploaded){
				cleanData()
				sweetalert("Error", "Ocurrió un error, intentalo de nuevo mas tarde", "error")
				return
			}
			await checkFile(nameTimestamp, setFile, setFileName)
			setLoading(false)
		})
		.catch(err => {
			setLoading(false)
			sweetalert("Error", "Ocurrió un error, intentalo de nuevo mas tarde", "error")
			cleanData()
		})
	}catch(e){
		// console.log(e)
		setLoading(false)
		sweetAlertExpulse('', 'Ocurrió un error, intentalo de nuevo más tarde', 'warning', logout)
		cleanData()
	  }
  }

  const getPresingUrl = async (fileName) => {
	try {
		const res = await axios.get(`${process.env.REACT_APP_API}/api/v1/billing/presinged/v2/${fileName}`, 
		{
			headers: {
				"Authorization": `Bearer ${datalogin.token}`
			}
		})
		return res.data.URL
	} catch (error) {
		console.error(error)
	
	}
  }

  const uploadToPresingUrl = async (url, file) => {
	try {
        const res = await axios({
            method: 'PUT',
            url,
            data: file,
			headers: {
				'Content-Type': file.type,
			}
        })
    } catch (error) {
        console.error(error.message)
        console.error(error.response.status)
        console.error(error.code)
		setLoading(false)
		sweetalert("Error", "Ocurrió un error, intentalo de nuevo mas tarde", "error")
		cleanData()
    }
  }

	return (
			<AppContext.Provider
					value={{
					datalogin,
					signIn,
					logout,
					loading,
					requestLogout,
					userData,
					UploadCsv,
					swalProps, setSwalProps,
					csvErrors,
					file, setFile,
					responseCsv,
					fileName, setFileName,
					cleanData,
					checkFile,
					fileToCheck, setFileToCheck
					}}
			>
					{children}
			</AppContext.Provider>
			);
	};

	export default AppState;