import React, { useContext, useEffect, useState } from 'react' 
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// components 
import UploadCsv from '../../screens/UploadCsv'
import Login from '../../screens/Login'
import NotResponsive from '../../screens/NotResponsive';
// context 
import AppContext from '../../context/app/AppContext'

// ruta PublicSharp, soilo se puede acceder al login 
// public Route, only login is allowed 
export const RouterPublic = () => {

    return (
      <BrowserRouter>
        {
          <Switch>
            <Route path="/login" component={Login} />
            <Route exact path="/not-responsive" component={NotResponsive} />
            <Redirect path="/" to="/login" />
          </Switch>
        }
      </BrowserRouter>
    );
  };
  
// ruta privada, solo disponible si el usuario esta autenticado 
// private route, only allowed if the user is authenticated 
  export const RouterPrivate = () => {

    const { userData } = useContext(AppContext)

    return (
      <BrowserRouter>
        {
          <Switch>
            <Route exact path="/upload-csv" component={UploadCsv} />
            <Route exact path="/not-responsive" component={NotResponsive} />
            <Redirect path="/" to="/upload-csv" />
          </Switch>
        }
      </BrowserRouter>
    );
  };