import React from 'react'
import AppState from '../context/app/AppState'
import Login from '../components/login/Login'

const LoginScreen = () => {
    return (
        <AppState>
            <Login />
        </AppState>

    )
}

export default LoginScreen