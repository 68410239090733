import React from 'react' 
import { useHistory } from "react-router-dom";
import '../Styles/styles.css'

const NotResponsive = () => { 
    const history = useHistory()

    const refreshPage = () => {
        window.screen.width > 868 && history.push('/')
        
    }

    return (
        <div className="not-responsive-container">
            <p className="not-responsive-text">No puedes ingresar a la herramienta desde un dispositivo movil</p>
            <p className="not-responsive-text">Por favor, ingresa desde tu ordenador</p>
            <button className="btn-yummy" onClick={refreshPage} style={{marginTop: 20}}>Refrescar pagina</button>
        </div>
    )
}

export default NotResponsive