import React, {useState, useContext} from 'react'
import AppContext from '../../context/app/AppContext'
import '../../Styles/login.css'
import '../../Styles/styles.css'
import Logo from '../../assets/logo.svg'
import TextField from '@mui/material/TextField';
import Loader from '../../helpers/Loader'

const Login = () => {

    const { signIn, loading} = useContext(AppContext)
    const [pswd, setPswd] = useState('')

    const handleChangePswd = (value) => {
        setPswd(value)
    }
    // funcion de googlelogin que trae los datos del usuario 
    // googlelogin func to get user info 
    const onSuccess = () => {
        signIn(pswd)
	}

    return(
        <div className="page-container">
        <div className="login-container">
        <img src={Logo} className="login-logo" alt="logo" />
            <p className="login-text">Ingresa para cargar CSV</p>
            <TextField 
                id="outlined-basic" 
                label="Contraseña" 
                type="password"
                variant="outlined" 
                size="small"
                style={{ margin: '20px 0px 30px' }}
                fullWidth 
                value={pswd}
                onChange={(e) => handleChangePswd(e.target.value)}
            />
            <div className="actions">
                {
                    loading ? 
                    <Loader color="#fff" /> 
                    :
                    <button className={pswd ? "btn-yummy btn-large btn-bg" : " btn-yummy btn-large btn-bg-disabled"} disabled={pswd ? false : true} onClick={onSuccess}>
                        <p>INGRESAR</p>
                    </button>
                }
            </div>
        </div>
        </div>
    )
}

export default Login