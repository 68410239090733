import React, { useState, useEffect } from 'react';
// libs
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export default function DateRange({ setDateRange }) {
  // days const for datepicker input
  const days = 1;
  // const that store today date
  const date = new Date();

  const [valueStart, setValueStart] = useState(
    moment(new Date(date.getTime() - days * 24 * 60 * 60 * 1000)).format(
      'yyyy-MM-DD'
    )
  );
  const [valueEnd, setValueEnd] = useState(
    moment(new Date()).format('yyyy-MM-DD')
  );
  // fechas de inicio para los inputs del buscador
  // date values for inputs on search engine
  const [startDate, setStartDate] = useState(
    new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());

  // valores que limitan el rango del datepicker del input de inicio
  // values that limit the range of datepicker on start input
  const [startMinDate, setStartMinDate] = useState(
    new Date(date.getTime() - 60 * 24 * 60 * 60 * 1000)
  );

  const [startMaxDate, setStartMaxDate] = useState(
    new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000)
  );
  const [endMaxDate, setEndMaxDate] = useState(new Date(date.getTime()));

  // funcion que cambia la fecha de inicio
  // func that change start date
  const onChangeStartDate = (date) => {
    // formatting date for request
    const dateFormat = moment(date).format('yyyy-MM-DD');

    // setting dates to shoy on input and datepicker
    setStartDate(date);
    setValueStart(dateFormat);
  };

  // funcion que cambia la fecha final
  // func that change end date
  const onChangeEndDate = (date) => {
    // formatting date for request
    const dateFormat = moment(date).format('yyyy-MM-DD');

    setEndDate(date);
    setValueEnd(dateFormat);
  };

  useEffect(() => {
    setDateRange({
      startDate: valueStart,
      endDate: valueEnd,
    });
  }, [startDate, endDate]);

  return (
    <div className="inputs-container">
      <div
        className="user-input-container"
        style={{
          margin: window.screen.width > 480 ? '0 15px 0 0' : '0 0 5px 0',
        }}>
        <p className="user-input-label m-0">Desde</p>
        <div className="flex">
          <DatePicker
            className="user-input"
            locale="es"
            selected={startDate}
            onChange={(date) => onChangeStartDate(date)}
            dateFormat="d/MM/yyyy"
            minDate={startMinDate}
            maxDate={startMaxDate}
          />
        </div>
      </div>
      <div
        className="user-input-container"
        style={{
          margin: window.screen.width > 480 ? '0 15px 0 0' : '0 0 5px 0',
        }}>
        <p className="user-input-label m-0">Hasta</p>
        <div className="flex">
          <DatePicker
            className="user-input"
            locale="es"
            selected={endDate}
            onChange={(date) => onChangeEndDate(date)}
            dateFormat="d/MM/yyyy"
            maxDate={endMaxDate}
          />
        </div>
      </div>
    </div>
  );
}
