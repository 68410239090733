import React, { useEffect } from 'react'
import lottie from "lottie-web";
import GreenLoader from './loader-green.json'
import './loader.css'


const Loader = () => {

    useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#react-logo"),
          animationData: GreenLoader
        });
      }, []);

    return(
        <div className='loader-container'>
            <div id="react-logo" />
        </div>
        // <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    )
}

export default Loader